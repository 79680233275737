<template>
  <div id="app">
    
    <!-- <nav-bar /> -->
    <div class="container-fluid ">
      <div class="main_container_loged row row-nograp" style="margin-top: 25px;">
        <side-bar class="col-2 p-0" style="margin-left:-15px; min-width: 250px"/>
        <div class="col-10 p-0 col-10-no-flex shadow2">
              <router-view></router-view>
            
        </div>
      </div>
    </div>
    <!-- <div class="container-fluid movil">
      <div class="row">
        <side-bar class="col-12 p-0" style="margin-left:-5px; "/>
        <div class="col-md-10 p-0 shadow2">
              <router-view></router-view>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import SideBar from "../../components/Sidebar.vue"
// import NavBar from "./components/Navbar.vue"
export default {
  components: {
    SideBar,
    // NavBar
  },
  data(){
    return{
      user: null
    }
  },
 async mounted() {
    console.log(this.$isLoading)
   
    this.$store.watch(
      (state) => state.user,
      (value) => {
        this.user = value
      }
    );

    this.user = localStorage.getItem("user");
    // if (this.user) {

    //   this.$store.commit("setUser", JSON.parse(this.user));
    // }
    // const store = localStorage.getItem("store");
    // if (store) {
    //   this.$store.commit("setStore", JSON.parse(store));
    // }
  },
  methods: {
    logout() {
      localStorage.removeItem("user");
      this.$store.commit("setUser", null);
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style >
.main_container_loged{
   justify-content: center;
 }
.row-nograp {
  flex-wrap: nowrap;
}
.col-10-no-flex{
  flex: 0 100%;
}
@media (max-width: 955px) {
 .main_container_loged{
   flex-direction: column;
 }
}
</style>
