<template>
  <div class="sidebar">
    <!-- <b-sidebar  visible no-header shadow no-close-on-route-change> -->
      
      <ul class="menu-list bg-gray">
        
        <li @click="boton (1)">
          <router-link to="/panel/LINETOR_Profile" class="bg-sidebar" active-class="active-menu">
            <b-icon icon="person" scale="1.5" :variant="(buttons==1 ? 'light' : 'dark')"></b-icon>
            <!-- <img class="ml-3 mr-2" :src="(buttons == 1 ? '../icon2_w.png' : '../icon2.png')" >  -->
             {{ miperfil }}
          </router-link>
        </li>
        <li @click="boton (2)">
          <router-link to="/panel/LINETOR_account" class="bg-sidebar" active-class="active-menu">
            <b-icon icon="clipboard-check" scale="1.5" :variant="(buttons==2 ? 'light' : 'dark')"></b-icon>
            <!-- <img class="ml-3 mr-2" :src="(buttons == 1 ? '../icon2_w.png' : '../icon2.png')" >  -->
             {{miscuentas}}
          </router-link>
        </li>
        
        <!-- <li v-if="rol==1">
          <router-link to="/admin/NAO_Promociones" class="bg-sidebar" active-class="active-menu">
            <b-icon-shop/> Promociones
          </router-link>
        </li> -->
        <!-- <li v-if="rol==1 || rol==99" class="shadow-bottom">
          <router-link to="/subcategories" class="bg-sidebar" active-class="active-menu">
            <b-icon-tags-fill/> Cerrar sesión
          </router-link>
        </li> -->
      </ul>
    <!-- </b-sidebar> -->
  
  </div>
</template>

<script>
export default {
//   watch: {
//     "$store.state.user": function (val) {
//       this.rol = val.rolId;
//     },

//   },
  // data: () => ({
  //   rol: null,
  //   buttons: 1,
    
  // }),
  
  // mounted(){
    
  //     let user = JSON.parse(localStorage.getItem('user')) || 0;
  //     this.rol = user.rol
  // },
    data() {
        return{
             rol: null,
             buttons: 1,
             miperfil : "",
             miscuentas:""
            // sidebarItem:[
            //     { id: 1 ,icon: 'circle-fill',titulo: 'Vision general', estadoButton: 'activo',},
            //     { id: 2 ,icon: 'circle-fill',titulo: 'Vision general', estadoButton: 'inactivo',},
            //     { id: 3 ,icon: 'circle-fill',titulo: 'Vision general', estadoButton: 'inactivo',},
            //     { id: 4 ,icon: 'circle-fill',titulo: 'Vision general', estadoButton: 'inactivo',},   
            //     { id: 5 ,icon: 'circle-fill',titulo: 'Vision general', estadoButton: 'inactivo',},
            //     { id: 6 ,icon: 'circle-fill',titulo: 'Vision general', estadoButton: 'inactivo',},
            //     { id: 7 ,icon: 'circle-fill',titulo: 'Vision general', estadoButton: 'inactivo',},
                           
                
                
            // ]
        }
        
    },
   async mounted(){
    
       let user = JSON.parse(localStorage.getItem('user')) || 0;
       this.rol = user.rol
       this.llenarCampos()
       console.log(this.miperfil)

       this.$store.watch(
      (state) => [state.recarga].join(),
      async () => {
        // this.showItemsNumber();
        // this.refreshButton();
       
        await this.llenarCampos()
        //console.log(this.textos)
      }
    );
   },
    methods: {
        boton(item){
            this.buttons = item
        },
        llenarCampos(){
         
          this.miperfil = JSON.parse(localStorage.getItem('text')).side_bar_mi_perfil
          this.miscuentas = JSON.parse(localStorage.getItem('text')).side_bar_mis_cuentas
        }
        
    }
};
</script>

<style >
.menu-list {
  width: 100%;
  padding-left: 10px;
  min-height: 100vh;
  height: 100%;
  margin: 0;
}
.menu-list li {
  list-style-type: none;
}
.menu-list li a {
  padding: 20px 5px 20px 5px;
  
  color: rgb(0, 0, 0);
  text-decoration: none;
  display: block;
  
  border-width: 2px;
  border-color: black;
  border-bottom: 2px solid rgb(226, 226, 226)
}

.menu-list li svg {
  margin-right: 1rem;
  margin-left: 0.80rem;
}

.active-menu {
  font-weight: bold;
  background-color: rgb(134, 134, 134);
  color:white !important;
 
  
}

.sidebar{
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: rgb(235, 235, 235);
  box-shadow: 0px 0px 4px 4px rgb(196, 196, 196);
}
.col-md-3{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
@media only screen and (max-width: 720px) {
  .menu-list {
    width: 100%;
    padding-left: 10px;
    min-height: 53vh;
    height: 100%;
    margin: 0;
  }
}
@media (max-width: 955px) {
 .sidebar{
    min-width: 100% !important;
    margin-left: 0 !important;
 }
 .menu-list{
  padding: 0;
  min-height: auto;
 }
 .col-10{
  max-width: 100%;
 }
}

</style>
